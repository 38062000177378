import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import SEO from '../components/seo'

const TechnologiesPage = () => (
  <StaticQuery query={technologiesQuery} render={data => {
    const { title, subTitle, image } = data.markdownRemark.frontmatter
    return (
      <>
        <SEO title="Technologies | BluePes" keywords={[`bluepes`]} />
        <BackgroundImage
          fluid={image.childImageSharp.fluid}
          className='background-image-technologies general-page-background'
        >
          <div className="general-page-gradient">
            <div className="general-page-header wrapper">
              <h1 className="general-page-title">{title}</h1>
              <p className="general-page-description">
                {subTitle}
              </p>
            </div>
          </div>
        </BackgroundImage>
        <section className="technologies-page">
          <div className="general-page-wrapper">
            <div className="technologies-wrapper wrapper">
              {data.allMarkdownRemark.edges.map(({ node }) => {
                return (
                  <article className="technology-item" key={node.id}>
                    <a className="technology-item-link">
                      <div className="technology-item-image">
                        <img
                          src={
                            require(
                              `../assets/technology-${ node.frontmatter.imageName }.svg`
                            )
                          }
                          alt={node.frontmatter.title} />
                      </div>
                      <div className="technology-item-info">
                        <div className="technology-item-header">
                          {node.frontmatter.header}
                        </div>
                        <div
                          className="technology-item-description"
                          dangerouslySetInnerHTML={{ __html: node.html }} />
                      </div>
                    </a>
                  </article>
                )
              })}
            </div>
          </div>
        </section>
      </>
    )
  }}
  />
)

export const technologiesQuery = graphql`
  query {
    allMarkdownRemark (
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
      filter: {fileAbsolutePath: {regex: "/content/technologies/"}}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            order
            header
            imageName
          }
        }
      }
    }
    markdownRemark(
      fileAbsolutePath: {regex: "/partial/general-pages-header/general-technologies-header.md/"}
    ) {
      frontmatter {
        title
        subTitle
        image {
          childImageSharp {
            fluid(
              maxWidth: 1920, 
              quality: 80,
              srcSetBreakpoints: [375, 480, 768, 1024, 1440, 1920]
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default TechnologiesPage
